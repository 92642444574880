import React, { useEffect, useMemo, useState, lazy, Suspense } from "react"
import { graphql } from "gatsby"

import { TemplateContextProvider } from "components/post/TemplateProvider"
import { PlacesContextProvider } from "components/post/PlacesProvider"

import { trackEvent } from "components/analytics"
import useContextTheme from 'components/utils/useContextTheme'
import usePageLoading from 'components/utils/usePageLoading'
import { filterStore, pageStore } from "state/store-zustand";


import SEO from "components/seo"
import Header from "components/header"
import ImpactArea from "components/sections/impactArea"
import Footer from "components/footer"

const Filters = lazy(() => import("components/filters/Filters"))

import EventsBlock from "components/post/blocks/eventsBlock"
import { eventsToSchema } from "components/utils/schemaUtils.js"

import "styles/pages.scss"
import "styles/map_page.scss"

const EventsIndex = props => {
  //console.log('EventsIndex ', pageContext, pageData)
  const { activity = "events", city, data, pageContext, showDesc = false } = props

  const { slug, events, eventsRecurring } = pageContext

  // Fix hydration issue
  // TODO: check if there are URL params also
  //const [hydrated, setHydrated] = useState(false)
  //useEffect(() => {
  //  setHydrated(true)
  //}, [])

  // TODO: Set form context
  //const currentActivity = activities.filter(item => item.slug === activity)[0]
  const currentActivity = undefined

  const initialEvents = useMemo(() => events && events.length > 0 ? events : [], [events])
  const allEvents = initialEvents.concat(eventsRecurring)

  trackEvent("events_page", "Events", props.location.href)

  const initialSchemaData =
    allEvents && allEvents.length > 0 ? eventsToSchema(allEvents) : []

  const [schemaData, setSchemaData] = useState(
    initialSchemaData ? initialSchemaData : null
  )

  const pageLoader = usePageLoading()
  const embedded = pageStore((state) => state.embedded)
  const hasParams = pageStore((state) => state.hasParams)

  const showCats = filterStore((state) => state.showCats)
  const setShowCats = filterStore((state) => state.setShowCats)

  // TODO: use zustand and reduce 1 render
  const [isClient, setClient] = useState(false)
  useEffect(() => {
    setClient(true)
    setShowCats(false)
  }, [])

  const themeContext = useContextTheme()
  const { theme, themeClass } = themeContext

  const node = data.allWpPage.edges[0]?.node
  const description = data?.allWpPage?.edges[0]?.node?.seo?.metaDesc

  const actvityParam = activity
    ? activity
    : currentActivity
      ? currentActivity
      : props.pageContext.activity

  const today = new Date()
  const year = today.getFullYear()
  const month = today.toLocaleString("default", { month: "short" })

  // Unique events handles for Events page
  const onChange = newFilters => {
    console.log("onChange ", newFilters)
    //setFilters(newFilters)
    //setActivity(filters.activities[0])
    const selectedActivity = newFilters.activities[0]
    // TODO: Implement reusable method for this route(s)
    const path =
      selectedCity == "all"
        ? `/events/${selectedActivity}`
        : `/cities/${selectedCity}/events/${selectedActivity}`

    console.log(`Navigate to path `, path)
    //navigate(path)
  }

  // TODO: Set SEO
  // Calendar of Events and Things to Do <today; this week> <month year> in <city>
  // Eventbrite: Vancouver, Canada Events Today | Eventbrite
  const titleBase = `Events & Things to Do`
  let pageNode = data.allWpPage.edges[0]?.node
    ? data.allWpPage.edges[0].node
    : {
      impactArea: {
        heading: titleBase,
      }
    }

  // Memoize selectedCity
  const selectedCity = useMemo(() => pageContext.cityDetails, [pageContext.cityDetails])

  // TODO: Make the name/title lookup reusable
  // TODO: CONTEXT: Move to Location context
  const displayCity = selectedCity
    ? selectedCity?.name
      ? selectedCity.name
      : selectedCity.title
    : "your city"

  const predicate = `${currentActivity?.title ? currentActivity.title + " " : ""}`
  const title =
    `${predicate}` +
    `Events & Things to Do ${selectedCity ? "in " + displayCity : "near you" } |` +
    ` ${month}, ${year} | Vibemap`
  pageNode.impactArea.heading = `${predicate}${pageNode.title}`
  const metaDesc = `${pageNode.impactArea.heading} : Check out the Vibemap calendar of events and things to do ${selectedCity ? "in " + selectedCity.name : "near you"}.`

  const seoData = useMemo (() => {

    return {
      title: title,
      metaDesc: metaDesc,
      schemaType: "WebPage",
    }
  })

  const section_key = "event-block"
  const pageClass = `page events ${pageLoader} ${embedded ? 'embedded' : ''} ${themeClass ? themeClass : ''}`

  if (!isClient) {
    return null
  }

  return (
    <div className={pageClass}>
      <TemplateContextProvider
        city={selectedCity}
        //embeddedCallback={setEmbedded}
        tryLocation={false}>
        <PlacesContextProvider initialEvents={initialEvents}>
          <SEO
            canonical={slug}
            description={seoData.metaDesc}
            lang="en-US"
            title={title}
            data={seoData}
            schemaData={schemaData}
          />
          <main>
            <Header />
            <ImpactArea data={pageNode} />
            <div className="post-body">
              <Filters
                activity={activity}
                page={"events"}
                size={"small"}
                showDateRange={true}
                showEventSearch={true}
                showVibes={true}
                showTags={true}
                onChange={onChange}
              />

              <section className="body">
                {showDesc && description && (
                  <div className="container">
                    <div className="narrow description">
                      <p>{description}</p>
                    </div>
                  </div>
                )}

                <section className="post-container" key={section_key}>
                  <div className="container">
                    <EventsBlock />
                  </div>
                </section>
              </section>
            </div>
          </main>
          <Footer />
        </PlacesContextProvider>
      </TemplateContextProvider>
    </div>
  )
}

export const eventsPageQuery = graphql`
  fragment eventpageDetailsFields on WpPage_Pagedetails {
    vibes {
      contentNodes {
        nodes {
          slug
          ... on WpActivity {
            id
            seo {
              focuskw
              metaDesc
            }
            title
          }
        }
      }
    }
    vibeset {
      ... on WpVibeset {
        id
        slug
        wpChildren {
          nodes {
            slug
          }
        }
      }
    }
  }

  fragment seoFields on WpPostTypeSEO {
    focuskw
    metaDesc
    title
  }

  query EventsTemplateQuery {
    allWpPage(filter: { slug: { eq: "events-template" } }) {
      edges {
        node {
          id
          databaseId
          title
          date
          pageDetails {
            ...eventpageDetailsFields
          }
          title
          seo {
            ...seoFields
          }
          impactArea {
            textColor
            backgroundColor
            backgroundType
            backgroundImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: DOMINANT_COLOR
                  )
                }
              }
            }
            heading
            vibeset {
              ... on WpVibeset {
                id
                title
                databaseId
                vibesetDetails {
                  gradientImage {
                    mediaItemUrl
                  }
                  gradientVideo {
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
// Add why did you render

export default EventsIndex