import React, { useEffect, useState } from "react"

import { pageStore } from "state/store-zustand";

// TODO: Load from design tokens
const blue = "#007acc"
const darkblue = "#004175"
const lightblue = "#e6f4ff"
const green = "#55BA47"
const darkgreen = "#3D8B3D"
const lightgray = "#f6f6ff"
const pink = "#ff1f78"

const themes = {
  default: {
    foreground: "#222222",
    background: "#FFFFFF",
    color: "#000000",
    eventForm: {
      title: 'Add Your Event',
      message: 'Search to add or update a place or event to Vibemap. Send any questions or suggestions. <a href="mailto:info@vibemap.com"> info@vibemap.com</a>',
      showVibePoints: true,
    },
    map: {
      boundary: {
        fill_color: lightblue,
        fill_opacity: 0.1,
        line_color: darkblue,
        line_opacity: 0.8,
        line_weight: 2,
        line_dash_array: [2, 2], // [1.4, 1]
      }
    },
  },
  light: {
    foreground: "#FFFFFF",
    background: "#222222",
    color: "#000000",
  },
  evanston: {
    map: {
      markerFeatured: {
        'background-color': '#007acc',
        'border-color': '#007acc',
        'latitude': 42.050,
        'longitude': -87.6834,
        'name': 'Downtown Evanston',
        'image': 'https://downtownevanston.org/sites/downtown-evanston/images/dte-logo.png',
        'style': 'marker', // Or tooltip
        'persistent': true,
      },
      titleCards: {
        'Evanston_Trick_Treat': {
          title: 'Downtown Evanston Trick or Treat',
          subtitle: 'October 31, 2020',
          description: 'Bring your kids downtown to trick or treating at local businesses! Stroll around downtown to stop at participating businesses for candy and other goodies. Meet up at Fountain Square (the plaza north of Davis Street) to pick up a map and take part in fall arts and crafts activities.  Please note that not all businesses are participating so look for spooky posters on the doors.Some businesses will have candy outside.',
        }
      }
    }
  },
  peoria: {
    foreground: "#FFFFFF",
    background: "#222222",
    color: "#000000",
    eventForm: {
      title: 'Add Your Event',
      message: 'Search to add or update a place or event to Vibemap. Send any questions or suggestions. <a href="mailto:info@vibemap.com"> info@vibemap.com</a>',
      showVibePoints: false,
    },
  }
}

const useContextTheme = () => {

  const theme = pageStore((state) => state.theme)

  const mainTheme = theme
  const whichTheme = theme ? theme : 'default'

  // Get the current theme and merge with default
  const currentTheme = themes[whichTheme]
  const themeTokens = {
    ...themes.default,
    ...currentTheme
  }

  return {
    themeClass: `theme-${whichTheme}`,
    theme: themeTokens
  }
}

export default useContextTheme