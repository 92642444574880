// TODO: How might this module be shared between app.vibemap.com and vibemap.com
export const trackEvent = (action = 'add_place', category = 'Discover', label = 'None', data = {}) => {
    //console.log('Inside track event analytics ', action);
    if (typeof window !== "undefined") {
        //console.log(`Trying to send analytics `, process.env.GOOGLE_TAG_MANAGER_ID);
        // Facebook
        if (window.fbq) {
            try {
                window.fbq('track', action, data);
            } catch (error) {
                console.log(`Error with Facebook analytics`);
            }

        }

        // Google
        const eventOptions = {
            // string - required - The object that was interacted with (e.g.video)
            event: `websiteEvent`,
            // string - describes the page or component
            category: category,
            // string - required - Type of interaction (e.g. 'play')
            action: action,
            // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
            label: label,
            // number - optional - Numeric value associated with the event. (e.g. A product ID)
            value: data.title,
            data: data
        }

        if (typeof window !== `undefined` && window.dataLayer) {

            dataLayer = window.dataLayer || [];

            dataLayer.push(eventOptions);
            console.log('Sent analytics for ', eventOptions)
        } else {
            console.warn(`Google Analytics could not be found.`);
        }

    }
}
